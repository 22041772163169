// Here you can add other styles
.text-white{
  color: #FFFFFF
}
.dash-main,
.sidebar.sidebar-fixed{
  transition: all 0.3s ease-in-out;
}
.btn-add-api svg{
  margin: 0 10px 0 0;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in, .modal.fade.show {
  opacity: 1;
}
.nav-link{
  color: #FFFFFF !important;
}

.fade.out {
  opacity: 0;
}
